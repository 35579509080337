
import './App.css';
import 'bootstrap';
import 'bootstrap/scss/bootstrap.scss';
import Amplify, {API, Auth} from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import awsconfig from './aws-exports';
import { withAuthenticator, AmplifySignOut, AmplifySignIn } from '@aws-amplify/ui-react';
import {useEffect, useState} from "react";

Amplify.configure(awsconfig);

function App() {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();
  const [vpnState, setVpnState] = useState({ state: undefined,
    instanceState: undefined,
    publicIp: undefined,
    ipAllocationId: undefined,
    domainName: undefined,
    ipAssociated: undefined,
    hostedZoneId: undefined,
    dnsIsSetToPublicIp: undefined });

  function refreshState() {
    return API.get("vpnapi", "/start-vpn", {}).then(data => {
        if (data.status === "success") {
          return data.data;
        } else {
          throw data.error;
        }
      });
  }

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);

      if (nextAuthState === AuthState.SignedIn) {
        refreshState().then(data => setVpnState(data));
      }
    });
  }, []);

  function turnOnVpn() {
    API.post("vpnapi", "/start-vpn", {})
      .then(data => {
        if (data.status === "success") {
          setVpnState(data.data)
        } else {
          console.error("Error while bringing VPN up", data);
        }
      });
  }

  function turnOffVpn() {
    API.del("vpnapi", "/start-vpn", {})
      .then(data => {
        if (data.status === "success") {
          setVpnState(data.data);
        } else {
          console.error("Error while bringing VPN down", data);
        }
      });
  }

  if (authState === AuthState.SignedIn && user) {

    return (
      <>
        <AmplifySignOut />

        <h1 className="h3 mb-3 font-weight-normal">VPN access <a href="#" onClick={() => { refreshState().then(data => setVpnState(data)); return false; }}>♺</a></h1>

        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <table className="table">
                <tbody>
                <tr>
                  <th scope="row">State</th>
                  <td>{vpnState.state === "up"
                    ? (<button type="button" onClick={turnOffVpn.bind(this)} className="btn btn-success">{vpnState.state}</button>)
                    : (<button type="button" onClick={turnOnVpn.bind(this)} className={"btn" + (vpnState.state === undefined ? " btn-secondary" : "") +
                      (vpnState.state !== undefined && vpnState.instanceState !== "pending" ? " btn-danger" : (vpnState.instanceState === "pending" ? " btn-info" : ""))}>{vpnState.state || "Unknown"}</button>)}</td>
                </tr>

                <tr>
                  <th scope="row">Instance</th>
                  <td>{vpnState.instanceState}</td>
                </tr>
                <tr>
                  <th scope="row">Public IP</th>
                  <td>{vpnState.publicIp}</td>
                </tr>
                <tr>
                  <th scope="row">DNS points to</th>
                  <td>{vpnState.dnsIsSetToPublicIp}</td>
                </tr>

                <tr>
                  <th scope="row">Domain</th>
                  <td>{vpnState.domainName}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </>
    );

  } else {
    return (<AmplifySignIn />);
  }

}

export default withAuthenticator(App);
